import React from "react";

export default class MyForm extends React.Component {
  constructor(props) {
    super(props);
    this.submitForm = this.submitForm.bind(this);
    this.state = {
      status: "",
    };
  }

  render() {
    const { status } = this.state;
    return (
      <div className="box">
        {/* <!-- add your custom form HTML here --> */}

        {status === "SUCCESS" ? (
          <>
            <svg
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 130.2 130.2"
              className="checked"
            >
              <circle
                className="path circle"
                fill="none"
                strokeWidth="6"
                strokeMiterlimit="10"
                cx="65.1"
                cy="65.1"
                r="62.1"
              />
              <polyline
                className="path check"
                fill="none"
                strokeWidth="6"
                strokeLinecap="round"
                strokeMiterlimit="10"
                points="100.2,40.2 51.5,88.8 29.8,67.5 "
              />
            </svg>
            <p class="congrats">Thanks !</p>
          </>
        ) : (
          <>
            <form
              onSubmit={this.submitForm}
              action="https://formspree.io/f/xdopjado"
              method="POST"
            >
              <label>Name:</label>
              <input type="text" name="name" />
              <label>Email:</label>
              <input type="email" name="email" />
              <label>Message:</label>
              <textarea name="message" id="field_message"></textarea>
              <button>Submit</button>
            </form>
          </>
        )}
      </div>
    );
  }

  submitForm(ev) {
    ev.preventDefault();
    const form = ev.target;
    const data = new FormData(form);
    const xhr = new XMLHttpRequest();
    xhr.open(form.method, form.action);
    xhr.setRequestHeader("Accept", "application/json");
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) return;
      if (xhr.status === 200) {
        form.reset();
        this.setState({ status: "SUCCESS" });
      } else {
        this.setState({ status: "ERROR" });
      }
    };
    xhr.send(data);
  }
}
