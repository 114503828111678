import React, { useRef, useEffect } from "react";
import TopHeader from "../Header/TopHeaders";
import "../../assets/scss/header.scss";
import { Parallax } from "react-parallax";
import BgParallax1 from "../../assets/images/bg-parralax1.svg";
import BgParallax2 from "../../assets/images/bg-parralax2.svg";
import BgParallax3 from "../../assets/images/bg-parralax3.svg";
import BgParallax4 from "../../assets/images/bg-parralax4.svg";
import { TimelineLite, Power3 } from "gsap";

function Header() {
  let tl = new TimelineLite();

  let app = useRef(null);
  let Title = useRef(null);
  let SubFirstLine = useRef(null);
  let SubSecondLine = useRef(null);

  const HeaderAnim = () => {
    tl.fromTo(
      app,
      {
        css: { opacity: "0" },
      },
      {
        css: { opacity: "1" },
      },
      0.6
    );
    tl.fromTo(
      Title,
      {
        y: 106,
        ease: Power3.easeOut,
      },
      {
        y: 0,
      },
      1
    );
    tl.fromTo(
      SubFirstLine,
      {
        y: 100,
        ease: Power3.easeOut,
      },
      {
        y: 0,
      },
      1.5
    );
    tl.fromTo(
      SubSecondLine,
      {
        y: 44,
        ease: Power3.easeOut,
      },
      {
        y: 0,
      },
      2
    );
  };

  useEffect(() => {
    HeaderAnim();
  });

  return (
    <header
      className="header container"
      ref={(el) => (app = el)}
      style={{ opacity: "0" }}
    >
      <TopHeader LogoName="Logo Fabrizio Faga" />
      <div className="block">
        <div className="block_title">
          <h1 className="title_primary" ref={(el) => (Title = el)}>
            Hi, I'm <span>Fabrizio</span>
          </h1>
        </div>
        <div className="title_sub">
          <p ref={(el) => (SubFirstLine = el)}>
            I’m a junior front-end developer based in belgium.
          </p>
          <p ref={(el) => (SubSecondLine = el)}>
            I was graduated from HEAJ (Namur) in 2019.
          </p>
        </div>
      </div>
      <div className="body_parallax">
        <Parallax
          bgImage={BgParallax1}
          bgImageAlt="parallax"
          strength={200}
          style={{ height: "100vh" }}
          className="parallax parallax_one"
        />
        <Parallax
          bgImage={BgParallax2}
          bgImageAlt="parallax"
          strength={200}
          style={{ height: "100vh" }}
          className="parallax parallax_two"
        />
        <Parallax
          bgImage={BgParallax3}
          bgImageAlt="parallax"
          strength={200}
          style={{ height: "100vh" }}
          className="parallax parallax_three"
        />
        <Parallax
          bgImage={BgParallax4}
          bgImageAlt="parallax"
          strength={200}
          style={{ height: "100vh" }}
          className="parallax parallax_four"
        />
      </div>
    </header>
  );
}

export default Header;
