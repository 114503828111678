import React from "react";
import "../../assets/scss/footer.scss";

function Footer() {
  function getYear() {
    var d = new Date();
    var y = d.getFullYear();
    return y;
  }

  return (
    <footer>
      <p>
        <span>{getYear()}</span> - Fabrizio Faga ©
      </p>
    </footer>
  );
}

export default Footer;
