import React, { useRef, useEffect } from "react";
import Arrow from "../../assets/images/arrow.svg";
import Earnz from "../../assets/images/earnz.png";
import BgEarnz from "../../assets/images/bg-earnz.png";
import Findr from "../../assets/images/findr.png";
import BgFindr from "../../assets/images/bg-findr.png";
import RickAndMorty from "../../assets/images/rickmorty.png";
import BgRickAndMorty from "../../assets/images/bg-rickmorty.png";
// import Fatality from "../../assets/images/fatality.png";
// import BgFatality from "../../assets/images/bg-fatality.png";
import { CSSPlugin, CSSRulePlugin, gsap, Power3 } from "gsap/all";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger, CSSPlugin, CSSRulePlugin);

const sections = [
  {
    id: "1",
    title: "Earnz",
    description:
      "CRM application. Where you can encode your customers and their invoices very quickly. This app is still in developement and you can find more features later.",
    background: `${BgEarnz}`,
    image: `${Earnz}`,
    lien: "https://www.earnz.fabriziofaga.be",
  },
  {
    id: "2",
    title: "Findr.",
    description:
      "Location app, where you can find the closest establishment to you, in a fun way.",
    background: `${BgFindr}`,
    image: `${Findr}`,
    lien: "https://fabriziofaga.be/projets/data-play/",
  },
  {
    id: "3",
    title: "API R&M",
    description:
      "A simple Rick and Morty database. You can find characters, episodes and planet's location from Rick and Morty's universe.",
    background: `${BgRickAndMorty}`,
    image: `${RickAndMorty}`,
    lien: "https://fabriziofaga.be/projets/rick-morty/",
  },
  // {
  //   id: "4",
  //   title: "Fatality",
  //   description:
  //     "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quibusdam, corrupti distinctio? Consectetur accusamus error tempore eius distinctio, voluptates, minus, est a omnis voluptatem veniam perferendis assumenda",
  //   background: `${BgFatality}`,
  //   image: `${Fatality}`,
  // },
];

function Projects() {
  const revealRefs = useRef([]);
  revealRefs.current = [];
  const rule = CSSRulePlugin.getRule("h2:after");

  const addToRefs = (el) => {
    if (el && !revealRefs.current.includes(el)) {
      revealRefs.current.push(el);
      console.dir(el.children[0]);
      // console.log(rule.cssRule);
      // console.log(el.offsetHeight);
    }
  };

  useEffect(() => {
    revealRefs.current.forEach((el, index) => {
      gsap.from(el, {
        scrollTrigger: {
          trigger: el,
          start: "top",
          toggleActions: "restart none none reverse",
        },
      });
      gsap.fromTo(
        // Titre
        el.firstElementChild.childNodes[0].children,
        {
          y: 60,
        },
        {
          y: 0,
          ease: Power3.easeOut,
          scrollTrigger: {
            trigger: el,
            start: "top 90%",
            toggleActions: "restart none none reverse",
          },
        }
      );
      gsap.fromTo(
        // after
        el.firstElementChild.childNodes[0].children[0].children[0],
        0.5,
        {
          css: { width: 0 },
        },
        {
          css: { width: 100 },
          ease: Power3.easeOut,
          scrollTrigger: {
            trigger: el.firstElementChild.childNodes[0].children,
            start: "top 98%",
            toggleActions: "restart none none reverse",
          },
        }
      );
      gsap.fromTo(
        // Images
        el.firstElementChild.childNodes[0].nextSibling.firstElementChild,
        0.4,
        {
          y: 200,
          opacity: 0,
        },
        {
          y: 0,
          opacity: 1,
          scrollTrigger: {
            trigger: el.children[0],
            start: "top 85%",
            toggleActions: "restart none none reverse",
          },
        }
      );
      gsap.fromTo(
        // Description
        el.firstElementChild.childNodes[0].nextSibling.nextSibling,
        {
          opacity: 0,
        },
        {
          opacity: 1,
          scrollTrigger: {
            trigger: el.children[0],
            start: "top 90%",
            toggleActions: "restart none none reverse",
            scrub: true,
          },
        }
      );
    });
  }, [rule]);
  return (
    <section className="projects">
      {sections.map(({ id, title, description, image, background, lien }) => (
        <div
          key={id}
          style={{
            backgroundImage: `url(${background})`,
          }}
          className="block"
          ref={addToRefs}
        >
          <div className="container">
            <div className="wrapper">
              <h2>
                {title} <span className="outline">outline</span>
              </h2>
            </div>
            <div className="image">
              <img src={image} alt={title} />
            </div>
            <div className="texte">
              <p className="description">{description}</p>
              <a href={lien} className="btn" target="_blank" rel="noreferrer">
                <span className="btn-txt">Discover {title}</span>
                <span className="circle">
                  <img src={Arrow} alt="arrow" />
                </span>
              </a>
            </div>
          </div>
        </div>
      ))}
    </section>
  );
}

export default Projects;
