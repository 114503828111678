import React from "react";
import Header from "../src/components/Header/Header";
import Works from "../src/components/Works/Works";
import Contact from "../src/components/Contact/Contact";
import Footer from "./components/Footer/Footer";
import "../src/assets/scss/global.scss";

function App() {
  return (
    <>
      <Header />
      <Works />
      <Contact />
      <Footer />
    </>
  );
}

export default App;
