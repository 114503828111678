import React from "react";
// import TransitionTitle from "./TransitionTitle";
import Projects from "./Projects";
import "../../assets/scss/works.scss";

function Works() {
  return (
    <>
      {/* <TransitionTitle /> */}
      <Projects />
    </>
  );
}

export default Works;
