import React, { useRef, useEffect } from "react";
import Form from "../Contact/Form";
import "../../assets/scss/contact.scss";
import { gsap, Power3 } from "gsap/all";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

function Contact() {
  let contactBlock = useRef(null);
  let trigger = useRef(null);

  const HeaderAnim = () => {
    gsap.fromTo(
      contactBlock,
      1,
      {
        y: 0,
      },
      {
        y: -60,
        ease: Power3.easeOut,
        scrollTrigger: {
          trigger: trigger,
          start: "top 100%",
          toggleActions: "play none none none",
          scrub: true,
        },
      }
    );
  };

  useEffect(() => {
    HeaderAnim();
  });
  return (
    <section className="contact" id="contact" ref={(el) => (trigger = el)}>
      <div className="block container" ref={(el) => (contactBlock = el)}>
        <div className="form">
          <h2>Contact me</h2>
          <Form />
        </div>
      </div>
    </section>
  );
}

export default Contact;
