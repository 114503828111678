import React from "react";
import Logo from "../../assets/images/logo.svg";
import PaperPlane from "../../assets/images/paper_plane.svg";

class TopHeader extends React.Component {
  render() {
    return (
      <div className="top">
        <div className="logo">
          <img src={Logo} alt={this.props.LogoName} />
        </div>
        <div className="contact">
          <a href="/#contact" className="contact-link">
            <p>Contact me</p>
            <span className="circle">
              <img src={PaperPlane} alt="Paper plane contact" />
            </span>
          </a>
        </div>
      </div>
    );
  }
}
export default TopHeader;
